
export const Foo = [
	{ id: '1', 
	imageName: 'pictures/Shapeandcolor3.jpg',
	heading: "Form und Farbe 3",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '2', 
	imageName: 'pictures/Iceberg5.jpg',
	heading: "Eisberg 5",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '3', 
	imageName: 'pictures/Icediamond2.jpg',
  heading: "Eisdiamant 2",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 120 Euro",
	},

	{ id: '4', 
	imageName: 'pictures/FormeFarbe4_1.jpg', 
  heading: "Form und Farbe 3",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '5', 
	imageName: 'pictures/Landschaft2.jpg', 
  heading: "Landschaft 2",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '6',
	imageName: 'pictures/Skulpturen3.jpg',
  heading: "Skulpturen 3",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '7', 
	imageName: 'pictures/Strukturen1.jpg', 
  heading: "Strukturen 1",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '8', 
	imageName: 'pictures/Eisberg3.jpg', 
  heading: "Eisberg 3",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '9', 
	imageName: 'pictures/Eishohle1.jpg', 
  heading: "Eishöhle",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '10', 
	imageName: 'pictures/Landschaft1.jpg',
	heading: "Landschaft 1",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '11', 
	imageName: 'pictures/Skulpturen2.jpg',
  heading: "Skulpturen 2",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '12', 
	imageName: 'pictures/Skulpturen4.jpg',
  heading: "Skulpturen 4",
  paragraphOne: "60x40cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 90 Euro",
	},

	{ id: '13', 
	imageName: 'pictures/Eisberg1.jpg',
  heading: "Eisberg 1",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},

	{ id: '14', 
	imageName: 'pictures/Abend.jpg',
  heading: "Abend",
  paragraphOne: "90x60cm",
  paragraphTwo: "Alu-Dibond mit Galerieschiene",
  paragraphThree: " | 130 Euro",
	},
];
