import styled, {css} from 'styled-components/macro';
import {IoMdArrowRoundForward} from 'react-icons/io';
import {IoArrowForward, IoArrowBack} from 'react-icons/io5'

export const HeroSection = styled.section`
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
  /* margin-bottom: 1rem; */
`;
export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;
export const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;
export const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.6) 100%,
    );
  }
`;
export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;
export const HeroContent = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 200px);
  color: #fff;
  @media screen and (max-width: 768px) {
    width: calc(100% - 100px);
  }
  

  h1 {
    font-size: clamp(2rem, 7vw, 5rem);
    font-weight: 400;
    /* text-transform: uppercase; */
    /* text-shadow: 0px 0px 20px rgba(0,0,0,0.4); */
    text-align:left;
    margin-bottom: 0.8rem;
    max-width: 547px;
    font-weight: 600;
  }
  h2 {
    /* font-size: clamp(0.8rem, 8vw, 1rem); */
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-weight: 400;
    /* text-transform: uppercase; */
    /* text-shadow: 0px 0px 20px rgba(0,0,0,0.4); */
    text-align:left;
    margin-bottom: 0.8rem;
    max-width:600px;
  }
  p {
    margin-bottom: 1.2rem;
    /* text-shadow: 0px 0px 20px rgba(0,0,0,0.4); */
  }

`;
export const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 0.5rem;
`;
export const SliderButtons = styled.div`
  position: absolute;
  bottom: 50px;
  right: 100px;
  display: flex;
  z-index: 10;
  @media screen and (max-width: 768px) {
    right: 50px;
  }
`;

export const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #1B93FA;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;
  
  &:hover {
    background: #394D6E;
    transform: scale(1.05);
  }
`;
export const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
`;
export const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;