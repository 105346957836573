export const BookPic = [
	{ id: '1', 
	imageName: 'book/1a-Alaska-Unga-Island-August-2017.jpg',
	title: '1a - Alaska, Unga Island - August 2017',
	subtitle: 'Der absolute Traum für einen Geologen. Eine einsame Küste und am Strand wird bei Ebbe ein ganzer versteinerter Wald freigelegt. Kleine Zweige, Äste, ganze Baumstämme und Wurzeln. Dazwischen strolcht sogar ein Polarfuchs umher.',
	},

	{ id: '2', 
		imageName: 'book/1b- Alasaka, Unga Island - August 2017.jpg',
		title: '1b - Alaska, Unga Island - August 2017',
	},
				
	{ id: '3', 
		imageName: 'book/1c- Alaska, Unga Island - August 2017.jpg',
		title: '1c - Alaska, Unga Island - August 2017',
	},

	{ id: '4', 
		imageName: 'book/2a- Alaska, Elfin Cove - August 2017.jpg', 
		title: '2a - Alaska, Elfin Cove - August 2017',
		subtitle: 'Ein kleines Fischerdorf am Ende eines ebenso kleinen Fjordes. Im Sommer gibt es hier sechzig und im Winter zwölf Einwohner, die sich in der schnuckeligen nebelverhangenen Elfenbucht gemütlich eingerichtet haben. Seit den 1920er-Jahren gibt es hier einen Laden, ein Restaurant und ein Dock und seit 1935 auch ein Postamt.',
	},

	{ id: '5', 
		imageName: 'book/2b- Alaska, Elfin Cove - August 2017.jpg',
		title: '2b - Alaska, Elfin Cove - August 2017',
	},

	{ id: '6', 
		imageName: 'book/2c- Alaska, Elfin Cove - August 2017.jpg',
		title: '2c - Alaska, Elfin Cove - August 2017',
	},

	{ id: '7', 
		imageName: 'book/3- Alaska, Nome - August 2017.jpg',
		title: '3 - Alaska, Nome - August 2017',
		subtitle: 'Ein Goldgräberdorf, wie es im Buche steht. Kneipen und Bars. Echte Goldgräber, die nach wie vor nach Gold suchen, wenn auch nicht am Strand wie seinerzeit, sondern offshore. Dazu unzählige Souvenirgeschäfte und mit etwas Glück außerhalb der Stadt Moschusochsen.',
	},

	{ id: '8', 
		imageName: 'book/4a- Alaska, Tracy Arm & Sawyer Gletscher - August 2017.jpg',
		title: '4a - Alaska, Tracy Arm & Sawyer Gletscher - August 2017',
		subtitle: 'Ein fantastischer Fjord. An dessen Ende ein Gletscher, der blaufarbene Eisberge mit krachendem Getöse ins Wasser schiebt. Am Ufer und auf den Eisschollen tummeln sich hunderte von Seehunden.',
	},

	{ id: '9', 
	imageName: 'book/4b- Alaska, TracyArm& Sawer Gletscher - August 2017.jpg',
	title: '4b - Alaska, Tracy Arm & Sawyer Gletscher - August 2017',
	subtitle: '',
	},

	{ id: '10', 
	imageName: 'book/5- Antarktis, Neko Harbour - Februar 2014.jpg',
	title: '5 - Antarktis, Neko Harbour - Februar 2014',
	subtitle: 'Eine Eis- und Schneelandschaft die ihresgleichen sucht. Eine mit Eisbergen gefüllte Bucht, ein Gletscher, hochaufragende Berge und oft gutes Wetter, Sonnenschein und blauer Himmel. Ein Aufstieg über Schnee, Eis und Fels belohnt mit einem grandiosen Rundblick. Manche Gäste schreien vor Glück, wirklich!',
	},
	
	{ id: '11', 
	imageName: 'book/6a- Antarktis, Snow Hill Island - Januar 2019.jpg',
	title: '6a - Antarktis, Snow Hill Island - Januar 2019',
	subtitle: 'Verlorener kann man sich kaum vorkommen. Eine einsame Hütte am Fuß einer steilen Felsklippe. Davor das stürmische Südpolarmeer, Schneetreiben und Eisschollen am Strand. Hier harrte der Geologe Nordenskjöld in seiner Hütte zwei Jahre lang aus und wartete auf Rettung. Mehr zu diesem sagenhaften Ort ist in der Geschichte „Antarktis – Snow Hill Island“ beschrieben.',
	},
	
	{ id: '12', 
	imageName: 'book/6b - Antarktis, Snow Hill Island - Januar 2019.jpg',
	title: '6b - Antarktis, Snow Hill Island - Januar 2019',
	subtitle: '',
	},
	
	{ id: '13', 
	imageName: 'book/6c- Antarktis, Snow Hill Island - Januar 2019.jpg',
	title: '6c - Antarktis, Snow Hill Island - Januar 2019',
	subtitle: '',
	},
	
	{ id: '14', 
	imageName: 'book/7- Argentinien, Buenos Aires, Tango - Dezember 2006.jpg',
	title: '7 - Argentinien, Buenos Aires, Tango - Dezember 2006',
	subtitle: 'Die meisten Touristen, die nach Buenos Aires kommen, schauen sich eine Tango-Show an. Ich gehe gerne in die Milongas. Das sind ganz normale Tanzlokale. Was bei uns am Ende eines Arbeitstages der Stammtisch oder das Café ist, das sind in Argentinien die Milongas. Hier treffen sich alle, die Tango tanzen wollen. Die Stimmung in so einem Lokal ist unbeschreiblich. Man muss das einfach erleben.',
	},
	
	{ id: '15', 
	imageName: 'book/8- Argentinien, Darwin Point, Marz 2009.jpg',
	title: '8 - Argentinien, Darwin Point, März 2009',
	subtitle: "Der Ría Deseado bietet sicherlich eine der atemberaubendsten und unwirtlichsten Wüstenlandschaften. Ein Ría ist ein Fluss, in den das Meer tidenabhängig viele Kilometer tief eindringen kann, der dann also anstatt Richtung Mündung, in Richtung Quelle fließt. Folgt man dem Ría Deseado ins Landesinnere, kommt man zum berühmten Darwin’s Rock.",
	},
	
	{ id: '16', 
	imageName: 'book/9- Australien, Uluru - November 2018.jpg',
	title: '9 - Australien, Uluru - November 2018',
	subtitle: 'Zu diesem Highlight muss ich wohl kaum etwas sagen. Jeder hat diesen Platz wohl schon einmal auf Bildern gesehen. Bei uns unter dem Namen Ayers Rock bekannt, erheben sich im tiefsten Outback von Australien mehrere riesige Sandstein-Monolithen aus einer Ebene mit von Eisenoxiden tiefrot gefärbtem Wüstensand.',
	},
	
	{ id: '17', 
	imageName: 'book/10- Azoren, Faial, Capelinhos Vulkan - Mai 2016.jpg',
	title: '10 - Azoren, Faial, Capelinhos Vulkan - Mai 2016',
	subtitle: 'Am Westende der Azoreninsel Faial liegt der Vulkan Capelinhos. Direkt hinter dem Leuchtturm aus dem Jahre 1903 brach im Jahr 1957 ein Vulkan etwa einen Kilometer vor der Küste aus. Die vulkanische Aktivität dauerte vierzehn Monate und schuf eine neue Insel, die sich letztendlich mit Faial verband. Bis heute ist das Neuland praktisch nicht bewachsen.',
	},
	
	{ id: '18', 
	imageName: 'book/11- Azoren, Pico, Weinanbau - Mai 2017.jpg',
	title: '11 - Azoren, Pico, Weinanbau - Mai 2017',
	subtitle: 'Direkt gegenüber von Faial liegt die Insel Pico, ein riesiger Vulkan, an dessen Fuß seit Jahrhunderten Wein angebaut wird. Hier wird unter unendlicher Mühe eine uralte Weinrebensorte angebaut. Jeweils zwei bis drei Weinreben sind von einer Mauer aus schwarzem Lavagestein umgeben. Die Mauer bietet Schutz vor den Winden.',
	},
	
	{ id: '19', 
	imageName: 'book/11a- Azoren, Pico, Weinanbau - Mai 2017.jpg',
	title: '11a - Azoren, Pico, Weinanbau - Mai 2017',
	subtitle: '',
	},
	
	{ id: '20', 
	imageName: 'book/12a Brasilien, Chapada Diamantina - Mai 2018.jpg',
	title: '12a Brasilien, Chapada Diamantina - Mai 2018',
	subtitle: 'Die Chapada Diamantina ist ein Mittelgebirge im brasilianischen Bundesstaat Bahia. Der zentrale Ort ist das kleine Städtchen Lençóis. Es stammt aus der Zeit der Diamantfunde. Die Landschaft ist geprägt von tiefen Canyons und Tafelbergen, Wasserfällen, kristallklaren Seen und einem Höhlensystem mit fantastischen unterirdischen Seen und Tropfsteinformationen. Dazu eine exuberante Flora und Fauna.',
	},
	
	{ id: '21', 
	imageName: 'book/12b- Brasilien, Chapada Diamantina - Mai 2018.jpg',
	title: '12b - Brasilien, Chapada Diamantina - Mai 2018',
	subtitle: '',
	},
	
	{ id: '22', 
	imageName: 'book/12c- Brasilien, Chapada Diamantina - September 2004.JPG',
	title: '12c - Brasilien, Chapada Diamantina - September 2004',
	subtitle: '',
	},
	
	{ id: '23', 
	imageName: 'book/13 Brasilien, Ilha Grande - Mai 2006.jpg',
	title: '13 -  Brasilien, Ilha Grande - Mai 2006',
	subtitle: 'Eine Insel, an der brasilianischen Küste zwischen Rio de Janeiro und São Paulo gelegen. Ein kleiner Ort und ansonsten nur pure Natur. Traumhafte Strände und kleine Buchten, subtropischer Regenwald im Landesinneren. Es gibt keine Straßen und keine Autos.',
	},
	
	{ id: '24', 
	imageName: 'book/14a- Brasilien, Lencois Maranhenses - April 2004.jpg',
	title: '14a - Brasilien, Lencois Maranhenses - April 2004',
	subtitle: 'Stellen Sie sich die Dünen der Sahara vor und legen Sie nun in jedes Dünental einen braunen, einen grünen oder einen blauen See hinein. Das sind die Lençois Maranhenses, die sich an der brasilianischen Nordküste des Bundesstaates Maranhão entlangziehen. Unter den Dünen liegt eine undurchlässige Lehmschicht „Lençóis“ oder zu Deutsch „Bettlaken“ genannt.',
	},
	
	{ id: '25', 
	imageName: 'book/14b- Brasilien, Lencois Maranhenses - Mai 2018.jpg',
	title: '14b - Brasilien, Lencois Maranhenses - Mai 2018',
	subtitle: '',
	},
	
	{ id: '26', 
	imageName: 'book/15a- Brasilien, Mariana - Mai 2018,.jpg',
	title: '15a - Brasilien, Mariana - Mai 2018',
	subtitle: 'Auf der Suche nach Sklaven und anderen Schätzen stieß zu Beginn des 18. Jahrhunderts eine Gruppe von Abenteurern ins karge Hochland des heutigen Bundesstaates Minas Gerais vor. An einem kleinen Fluss namens Rio das Velhas, direkt beim heutigen Städtchen Mariana, fand man dann, was fast zweihundert Jahre viele gesucht aber nie gefunden hatten. Gold, und zwar in ungeheuren Mengen.',
	},
	
	{ id: '27', 
	imageName: 'book/15b- Brasilien, Mariana - Mai 2018.jpg',
	title: '15b - Brasilien, Mariana - Mai 2018',
	subtitle: '',
	},
	
	{ id: '28', 
	imageName: 'book/16a- Chile, Atacama,Mondtal - Dezember 2015.jpg',
	title: '16a - Chile, Atacama,Mondtal - Dezember 2015',
	subtitle: 'Auf die Frage, welcher der schönste Nationalpark in Südamerika ist, habe ich zwei Antworten. Der eine ist die chilenische Atacama-Wüste. Einen größeren Kontrast zum zweiten, dem Nationalpark Torres del Paine, kann man sich kaum vorstellen. Die Atacama ist die trockenste Wüste der Welt. An einigen Plätzen wie zum Beispiel im Mondtal und im Todestal gibt es außer den Touristen keinerlei Leben.',
	},
	
	{ id: '29', 
	imageName: 'book/16b- Chile, Atacama, Miscantes & Miniques - Juli 2006.jpg',
	title: '16b - Chile, Atacama, Miscantes & Miniques - Juli 2006',
	subtitle: '',
	},
	
	{ id: '30', 
	imageName: 'book/16c- Chile, Atacama, Mondtal - November 2008.jpg',
	title: '16c - Chile, Atacama, Mondtal - November 2008',
	subtitle: '',
	},
	
	{ id: '31', 
	imageName: 'book/17a- Chile, Torres del Paine Nationalpark - Dezember 2019.jpg',
	title: '17a - Chile, Torres del Paine Nationalpark - Dezember 2019',
	subtitle: 'Der zweite Nationalpark in Südamerika, der mir unglaublich gut gefällt, ist der Nationalpark Torres del Paine. Er liegt im tiefen Süden von Chile und besticht mit allem, was man sich in Patagonien wünschen kann. Hohe, steil aufragende Berge und Zinnen, die Cuernos und die Torres. Unzählige Wasserfälle, Gletscher, Gletscherseen und reißende Flüsse sowie eine exuberante Tier- und Pflanzenwelt.',
	},
	
	{ id: '32', 
	imageName: 'book/17b- Chile Torres del Paine - Februar 2010.jpg',
	title: '17b - Chile Torres del Paine - Februar 2010',
	subtitle: '',
	},
	
	{ id: '33', 
	imageName: 'book/17c- Chile, Torres del Paine - Marz 2008.jpg',
	title: '17c - Chile, Torres del Paine - März 2008',
	subtitle: '',
	},
	
	{ id: '34', 
	imageName: 'book/18a- Chile, Puerto Williams - Februar 2012.jpg',
	title: '18a - Chile, Puerto Williams - Februar 2012',
	subtitle: 'Puerto Williams ist mit seinen 2300 Einwohnern bestenfalls ein kleines Kaff und nebenbei die südlichste Stadt der Welt, hat aber trotzdem etwas ganz Besonderes zu bieten. Im Museum Martin Gusinde wird die Geschichte der Yámana-Ureinwohner dargestellt. Und dann gibt es noch die Kneipe Micalvi. Sie ist auf einem ehemaligen deutschen Rheindampfer zu finden.',
	},
	
	{ id: '35', 
	imageName: 'book/18b- Chile, Porto Williams - Februar 2012.jpg',
	title: '18b - Chile, Porto Williams - Februar 2012',
	subtitle: '',
	},
	
	{ id: '36', 
	imageName: 'book/19- Chile, Valparaiso, Wandmalereien - Marz 2012.jpg',
	title: '19 - Chile, Valparaiso, Wandmalereien - März 2012',
	subtitle: 'Die Oberstadt von Valparaíso besuche ich immer wieder gerne. Hinauf kommt man entweder durch endlose Treppenstufen oder durch die berühmten Schrägseilaufzüge, die Ascensores, wenn sie denn nicht gerade repariert werden, und das passiert ziemlich oft. Einmal in der Oberstadt , entdeckt man überall an den Wänden, auf den Straßen und an den Mauern hochwertige wunderbare Kunstwerke. Wandmalereien von Graffiti-Künstlern.',
	},
	
	{ id: '37', 
	imageName: 'book/20- Danemark, Erbseninseln - September 2020.jpg',
	title: '20 - Dänemark, Erbseninseln - September 2020',
	subtitle: 'Die Erbseninseln sind der östlichste Punkt von Dänemark. Im Prinzip bestehen sie aus zwei kleinen, aber bewohnten Inseln, die durch einen winzigen, natürlichen Wasserkanal voneinander getrennt sind. Über diesen Kanal gibt es eine Brücke, die die beiden Inseln für Fußgänger oder Radfahrer miteinander verbindet.',
	},
	
	{ id: '38', 
	imageName: 'book/21a- Ecuador, Galapagos, Bartholome - November 2009.jpg',
	title: '21a - Ecuador, Galapagos, Bartholomé - November 2009',
	subtitle: 'Ich glaube zu den Galapagos-Inseln muss ich nicht viel sagen. Eine Gruppe von Vulkaninseln die fünfhundert Kilometer westlich der ecuadorianischen Küste aus dem Meer ragen. Sie bestechen sowohl durch ihre landschaftliche Schönheit als auch durch ihre einmalige Tierwelt. Blaufußtölpel, Rotfußtölpel, Albatrosse, Fregattenvögel, Pinguine, Robben, Leguane und die berühmten Drachenköpfe.',
	},
	
	{ id: '39', 
	imageName: 'book/21b- Ecuador, Galapagos Espanola Ponto Suarez - November 2009.jpg',
	title: '21b - Ecuador, Galapagos Espanola Ponto Suarez - November 2009',
	subtitle: '',
	},
	
	{ id: '40', 
	imageName: 'book/21c- Ecuador Galapagos, Espanola, Ponto Suarez November 2009.jpg',
	title: '21c - Ecuador Galapagos, Espanola, Ponto Suarez November 2009',
	subtitle: '',
	},
	
	{ id: '41', 
	imageName: 'book/21d- Ecuador, Galapagos, Santiago, Puerto Egas - November 2009.jpg',
	title: '21d - Ecuador, Galapagos, Santiago, Puerto Egas - November 2009',
	subtitle: '',
	},
	
	{ id: '42', 
	imageName: 'book/22a- Ecuador, Markt Sasquisili - November 2009.jpg',
	title: '22a - Ecuador, Markt Sasquisili - November 2009',
	subtitle: 'Märkte haben für mich überall auf der Welt einen ganz besonderen Reiz. Aber es gibt zwei Bauernmärkte in Ecuador, Otavalo und Sasquisili, die für mich alle anderen in den Schatten stellen. Hierher kommen die Einheimischen aus den umliegenden Dörfern, um ihre landwirtschaftlichen Produkte zu verkaufen. Obst, Gemüse, Meerschweinchen, Schweine, Ziegen, Schafe und was es noch so alles gibt.',
	},
	
	{ id: '43', 
	imageName: 'book/22b- Ecuador, Markt Sasquisili - November 2009.jpg',
	title: '22b - Ecuador, Markt Sasquisili - November 2009',
	subtitle: '',
	},
	
	{ id: '44', 
	imageName: 'book/23- England, Scilly Isles - Juni 2015 .jpg',
	title: '23 - England, Scilly Isles - Juni 2015',
	subtitle: 'Wenn man ein Karibikgefühl bekommen will, aber nicht so weit fliegen möchte, dann empfehle ich die Scilly Isles. Aquamarinfarbenes Meer, schneeweiße Strände, grandiose Granitformationen und auf einer der Inseln der berühmte Tresco Abbey Garden. Ein absolutes Wunder der englischen Gartenkunst. Hier gedeihen dank des Golfstromes nicht nur subtropische, sondern sogar tropische Gewächse.',
	},
	
	{ id: '45', 
	imageName: 'book/24a- Falklands, New Island - Dezember 2018.jpg',
	title: '24a - Falklands, New Island - Dezember 2018',
	subtitle: 'Feiner weißer Sand unter unseren Füßen, schon wieder so ein Karibikgefühl. Am Strand gleich die ersten Begegnungen, zum Beispiel mit der Kelpgans, der Magellangans, dem Austernfischer, der Blutschnabelmöwe, einer Familie von flugunfähigen Dampfschiffenten und der Patagonischen Schopfente sowie dem 1969 gestrandeten Wrack des Minensuchboots PROTECTOR III. Dann führt ein Spaziergang auf die andere Seite der Insel.',
	},
	
	{ id: '46', 
	imageName: 'book/24b- Falklands, New Island - Januar 2016.jpg',
	title: '24b - Falklands, New Island - Januar 2016',
	subtitle: '',
	},
	
	{ id: '47', 
	imageName: 'book/25- Irland, Dublin, Famine Memorial - Juni 2017.jpg',
	title: '25 - Irland, Dublin, Famine Memorial - Juni 2017',
	subtitle: 'Ich liebe Skulpturen, große und kleine, antike und moderne. Aber die Skulptur, die mich von allen, die ich bisher gesehen habe, am meisten beeindruckt hat, ist die des Famine Memorial in Dublin. Die Skulptur wurde zum Gedenken an die große Hungersnot in Irland 1845 bis 1852 errichtet. Sie wurde von Rowan Gillespie aus Bronze geschaffen und 1997 errichtet. Dargestellt sind fünf Menschen und ein Hund, alle dem Hungerstod nahe. ',
	},
	
	{ id: '48', 
	imageName: 'book/26- Nordirland, Giant Causeway - Juni 2018.jpg',
	title: '26 - Nordirland, Giant Causeway - Juni 2018',
	subtitle: 'Es gibt geologische Highlights, die auch den Nicht-Geologen faszinieren. Die Basaltsäulen des Giant Causeway sind so ein Beispiel. Tausende von meist sechseckigen Basaltsäulen erstrecken sich hier über den Strand und wie ein übergroßer Ponton ins Meer hinein, wo sie dann langsam unter den Wellen verschwinden. In Schottland tauchen sie wieder auf, aber dazu später. Diese Basaltsäulen entstehen, wenn flüssiges Magma sich langsam und ohne Bewegung abkühlt.',
	},
	
	{ id: '49', 
	imageName: 'book/27a- Irland, Tory Island - Juni 2015.jpg',
	title: '27a - Irland, Tory Island - Juni 2015',
	subtitle: 'Tory Island ist eine im Nordwesten Irlands gelegene Insel mit etwas über hundert Einwohnern. Bis zu seinem Tod 2018 war Patsy Dan Rodgers der König von Toraigh. Seit seinem Tod ist die Position vakant. Rodgers hatte als König eine repräsentative Funktion. Besucher begrüßte der König persönlich am Pier und abends im örtlichen Pub. Toraigh ist etwa vier Kilometer lang und rund einen Kilometer breit.',
	},
	
	{ id: '50', 
	imageName: 'book/27b- Irland, Tory Island, King Patsy Dan Rodgers - Juni 2015.jpg',
	title: '27b - Irland, Tory Island, King Patsy Dan Rodgers - Juni 2015',
	subtitle: '',
	},
	
	{ id: '51', 
	imageName: 'book/28a- Frankreich, Luberon - Juni 2009.jpg',
	title: '28a - Frankreich, Luberon - Juni 2009',
	subtitle: 'Zur Zeit der Lavendelblüte ist die Provence touristisch überlaufen. An den Straßen entlang der Felder stehen die Busse oft kilometerlang, um den Touristen ein Foto auf das lilablaufarbene Meer vor ihnen zu ermöglichen. In der Gebirgskette des Luberon ist es auch zur Lavendel- und Sonnenblumenblüte ziemlich leer. Die kleinen Dörfer liegen einsam und verlassen, viele Einwohner haben die Region verlassen. Der Bäcker öffnet nur am Vormittag für drei Stunden, die Post einmal in der Woche.',
	},
	
	{ id: '52', 
	imageName: 'book/28b- Frankreich, Luberon, Kloster Seanque - Juli 2009.jpg',
	title: '28b - Frankreich, Luberon, Kloster Seanque - Juli 2009',
	subtitle: '',
	},
	
	{ id: '53', 
	imageName: 'book/29a- Franzosich Guayana - Iles du Salut - April 2015.jpg',
	title: '29a - Französich Guayana - Iles du Salut - April 2015',
	subtitle: 'Berühmt wurden die Inseln vor allem durch den Roman „Papillon“ und seine Verfilmung im Jahr 1973. Von 1852 bis 1953 betrieb Frankreich hier eine der grausamsten Strafkolonien. Die meisten der Sträflinge starben schon nach wenigen Monaten an Gelbfieber, Malaria oder anderen tropischen Krankheiten. Der Kontrast der grauenvollen Vergangenheit zur Gegenwart könnte kaum größer sein.',
	},
	
	{ id: '54', 
	imageName: 'book/29b- Franzosisch Guayana, Iles du Salut - April 2015.jpg',
	title: '29b - Französisch Guayana, Iles du Salut - April 2015',
	subtitle: '',
	},
	
	{ id: '55', 
	imageName: 'book/29c- Franzosisch Guayana, Iles du Salut, Goldaguti - April 2015.jpg',
	title: '29c - Französisch Guayana, Iles du Salut, Goldaguti - April 2015',
	subtitle: '',
	},
	
	{ id: '56', 
	imageName: 'book/30- Gronland, Disco Bucht - September 2019.JPG',
	title: '30 - Grönland, Disco Bucht - September 2019',
	subtitle: 'Zumindest auf der Nordhalbkugel der Erde gibt es wohl keinen besseren Platz als die Diskobucht, um Eisberge zu bewundern. In der Bucht liegt Ilulissat mit seinem berühmten Eisfjord, dem Kangia. Es scheint, als würden die gewaltigen Eisriesen in einem Wettstreit um die schönsten Farben und die außergewöhnlichsten Formen liegen. Dazwischen die kleinen Boote der einheimischen Fischer. Wirklich ein unvergessliches Spektakel.',
	},
	
	{ id: '57', 
	imageName: 'book/31- Gronland, Qeqertarsuag - September 2019.jpg',
	title: '31 - Grönland, Qeqertarsuag - September 2019',
	subtitle: 'Eine nicht ganz einfache Wanderung von gut zwei Stunden entlang der Küste und über den Fluss Rode Elv führt zu den schönsten Basaltfeldern Grönlands. Der Weg schlängelt sich auf dem letzten Teil entlang einer Steilküste und durch üppige Vegetation hindurch zu den Skulpturen aus Basaltsäulen. Vorsicht ist geboten, der Weg ist schlammig und rutschig.',
	},
	
	{ id: '58', 
	imageName: 'book/32- Gronland, Uummannaq, August 2018.jpg',
	title: '32 - Grönland, Uummannaq, August 2018',
	subtitle: 'Eigentlich handelt es sich bei Uummannaq lediglich um eine Insel, die sich aus dem Meer erhebt. Die Insel selbst besteht aus einem steilen Berg, an dessen Fuß sich eine kleine Siedlung befindet. Das ist eigentlich alles. Und dieses „alles“ ist einfach eine Schönheit unter blauem Himmel, die Worte, jedenfalls meine Worte, nicht beschreiben können.',
	},
	
	{ id: '59', 
	imageName: 'book/33- Hawaii, Kalapana, Lava - April 2017.jpg',
	title: '33 - Hawaii, Kalapana, Lava - April 2017',
	subtitle: 'Hier münden die Lavaströme des Puu Oo nach einer zwölf Kilometer langen Reise über Land in den Ozean. Auch wenn der Vulkan nicht aktiv ist und man das Spektakel der ins Meer fließenden Lava nicht erleben kann, ist das erstarrte Lavafeld selbst eine Show für sich. Die Stricklava bildet hier fantastische Formen in irisierenden Farben. Moderne Kunst von der Natur geschaffen. Unter der erstarrten Kruste fließt die rotglühende Lava weiter.',
	},
	
	{ id: '60', 
	imageName: 'book/34- Island, Gletscherhohlen - Februar 2017.jpg',
	title: '34 - Island, Gletscherhöhlen - Februar 2017',
	subtitle: 'Einige der Gletscher auf Island, zum Beispiel der Mýrdalsjökull oder der Vatnajökull haben an der Gletscherzunge oftmals große Gletscherhöhlen, die sich Jahr für Jahr verändern. Ein Abstieg ist nur im Winter und mit erfahrenen und zertifizierten Gletscherguides möglich. Mit Schutzhelm und Steigeisen betritt man eine bizarre unterirdische Welt mit blau schimmernden Eisformationen über einem, von denen das Gletscherwasser herabtropft und am Boden kleine Gletscherflüsse bildet. Eine nasse und kalte Angelegenheit.',
	},
	
	{ id: '61', 
	imageName: 'book/35- Island, Hafnarholmi, Papageitaucher - Juni 2019 .jpg',
	title: '35 - Island, Hafnarholmi, Papageitaucher - Juni 2019',
	subtitle: 'Vom kleinen Hafen in Hafnarholmi sind es nur wenige Schritte bis zu einem kleinen Hügel, auf dem Papageitaucher nisten. Hier ist auch eine kleine Beobachtungsstation eingerichtet. Ein perfekter Platz, um die Tiere zu beobachten, wenn sie, den Schnabel voller Sandaale, zurück zu ihren Nestern fliegen.',
	},
	
	{ id: '62', 
	imageName: 'book/36- Island, Hvervagerdi - Februar 2017.jpg',
	title: '36 - Island, Hvervagerdi - Februar 2017',
	subtitle: 'Heiße Quellen, Fumarolen und Schlammlöcher gibt es überall auf Island. In Hveragerði wird die Erdwärme für den Anbau von Obst und Gemüse in riesigen Gewächshäusern genutzt. Man kann etwa drei Kilometer durch das Reykjaldur, das rauchende Tal, wandern. Durch das Tal fließt ein Fluss, dessen Wasser durch den Vulkanismus geothermal aufgeheizt wird. Hier lässt es sich, mitten in der Natur, wunderbar baden.',
	},
	
	{ id: '63', 
	imageName: 'book/37a- Island, Jokullsarlon Lagune - September 2013.jpg',
	title: '37a - Island, Jökullsarlon Lagune - September 2013',
	subtitle: 'Auf Island liegt die 8100 Quadratkilometer große Vatnajökull-Eiskappe. Anfang des 20. Jahrhunderts erstreckte sich der Gletscher noch 250 Meter in den Ozean hinein. Bis 2004 hat sich das Ende des Gletschers drei Kilometer landeinwärts zurückgezogen. Dadurch hat sich eine schnell wachsende Lagune gebildet, in der die Eisberge treiben, die vom Gletscher abbrechen („kalben“). Die Eisberge der Lagune drängen durch einen engen Kanal hinaus ins Meer und werden dann bei der nächsten Flut an den Strand geworfen.',
	},
	
	{ id: '64', 
	imageName: 'book/37b- Island, Jokullsarlon - Februar 2017.jpg',
	title: '37b - Island, Jökullsarlon - Februar 2017',
	subtitle: '',
	},
	
	{ id: '65', 
	imageName: 'book/38- Island, Svartifoss - Februar 2017.jpg',
	title: '38 - Island, Svartifoss - Februar 2017',
	subtitle: 'Noch einmal Basaltsäulen. Ein Wasserfall ergießt sich über eine senkrechte Kante in eine enge Schlucht aus wunderbaren Basaltsäulen. Der Svartifoss, der schwarze Wasserfall, ist nach der Farbe des umgebenden Gesteins benannt. Wie Orgelpfeifen erscheinen die Basaltsäulen, die die Schlucht einrahmen.',
	},
	
	{ id: '66', 
	imageName: 'book/39- Italien, E5 - August 2010.jpg',
	title: '39 - Italien, E5 - August 2010',
	subtitle: 'Grundsätzlich erstreckt sich der E5 vom französischen Brest bis nach Verona. Der schönste und technisch anspruchsvollste Teilabschnitt verläuft zwischen Oberstdorf und Meran. Diese Strecke lässt sich in sechs Etappen bewältigen. Atemberaubende Ausblicke von Tausender-Gipfeln, urige Hütten und glasklare Seen, Almwiesen, Steinböcke. All das ist die Belohnung für insgesamt fast 5000 Höhenmeter im Aufstieg und über 6000 Höhenmeter im Abstieg auf einer Strecke von über 100 Kilometern.',
	},

	{ id: '67', 
	imageName: 'book/40- Jan Mayen - Juni 2019.jpg',
	title: '40 - Jan Mayen - Juni 2019',
	subtitle: 'Jan Mayen betört die Sinne durch seine Lage im arktischen Ozean und durch seine Unnahbarkeit. Die kleine Insel liegt fast mittig im Nordpolarmeer zwischen Grönland, Spitzbergen, Norwegen und Island. Fast immer ist diese winzige, fast unbewohnte Insel in Nebel gehüllt. Es gibt dort lediglich eine kleine norwegische Wetterstation und ansonsten nur einen Strand aus schwarzem und grün glänzendem Lavasand, einen großen ins Meer mündenden Gletscher und eine Bergspitze. Alles wie gesagt fast immer verhüllt von einer Nebelwand.',
	},

	{ id: '68', 
	imageName: 'book/41- Jordanien, Petra - Oktober 2010.jpg',
	title: '41 - Jordanien, Petra - Oktober 2010',
	subtitle: 'Der Zugang zu diesem Wunder führt durch eine lange, enge Schlucht. Bis zum letzten Moment ahnt niemand, was einen am Ausgang erwartet. Einmal aus der dunklen Schlucht ins gleißende Licht herausgetreten, breitet sich vor einem ein unglaubliches Amphitheater aus. Die Stadt Petra ist weder aus Lehm, Ziegeln, Holz oder anderen Materialien erbaut worden. Sie ist in den Sandstein der Schlucht hineingemeißelt worden. Die Stadt ist so einmalig, hat so viele fantastische Gebäude, dass eine nähere Beschreibung den Rahmen hier sprengen würde.',
	},

	{ id: '69', 
	imageName: 'book/42a- Jordanien, Wadi Rum - Oktober 2010.jpg',
	title: '42a - Jordanien, Wadi Rum - Oktober 2010',
	subtitle: 'Die Felswände des Wadi Rum bestehen aus rotem Sandstein und hellem Granit. Unter den Felswänden breitet sich eine fantastische Dünenlandschaft aus. Durch die Jahrmillionen der Erosion wurde der weiche rote Sandstein, der auf einem Fundament aus harten magmatischen Gesteinen steht, in bizarre Formen und Skulpturen geschliffen. Die früheren Bewohner hinterließen ihre Spuren in Form von Felszeichnungen.',
	},	
	
	{ id: '70', 
	imageName: 'book/42b- Jordanien, Wadi Rum - Oktober 2010.jpg',
	title: '42b - Jordanien, Wadi Rum - Oktober 2010',
	subtitle: '',
	},

	{ id: '71', 
	imageName: 'book/43- Kanada, Cape Dorset - September 2017.jpg',
	title: '43 - Kanada, Cape Dorset - September 2017',
	subtitle: 'Cape Dorset ist eine kleine Inuit-Siedlung wie viele im kanadischen Territorium Nunavut. Aber etwas ist in Cape Dorset anders. Hier befindet sich das Zentrum der Inuit-Kunst. Zum einen werden hier ausdrucksvolle Skulpturen aus Speckstein, Quarz, Dolomit und Marmor hergestellt. Außerdem erlernten die Inuit hier von den Europäern die verschiedenen Steindrucktechniken, mit denen sie inzwischen weltweit bekannte fantastische Drucke herstellen.',
	},
	
	{ id: '72', 
	imageName: 'book/44a- Kanada, Smoking Hills - August 2017.jpg',
	title: '44a - Kanada, Smoking Hills - August 2017',
	subtitle: 'In den frühen Morgenstunden ziehen Nebelschwaden über das Land, was man in der Ferne vom Schiff aus erkennt. Erst wenn man sich der Küste nähert, ist zu erkennen, dass man hier nicht nur Nebel sieht. Es sind Rauchschwaden. Die gesamte Steilküste brennt. Etwas Chemie gefällig? Holzreste in einer Gesteinslage aus Braunkohle haben sich vor vielen hundert Jahren vielleicht durch einen Blitzeinschlag entzündet, und es entwickelte sich mit dem Luftsauerstoff und der Meeresfeuchte ein Schwelbrand.',
	},
	
	{ id: '73', 
	imageName: 'book/44b- Kanada, Smoking Hills - August 2017.jpg',
	title: '44b - Kanada, Smoking Hills - August 2017',
	subtitle: '',
	},
	
	{ id: '74', 
	imageName: 'book/45- Kanaren, El Hierro, El Sabinar - November 2021 .jpg',
	title: '45 - Kanaren, El Hierro, El Sabinar - November 2021',
	subtitle: 'Abseits der Routen, die man auf El Hierro mit dem Bus erreichen kann, liegt der Wacholderwald El Sabinar. Durch die hier herrschenden Fallwinde sind die zum Teil viele hundert Jahre alten Bäume völlig verdreht. Einige von ihnen berühren daher mit ihren ehemaligen Wipfeln sogar den Boden. Extreme Windflüchter. Dazu kommt, dass viele der alten Bäume völlig tot erscheinen, bis man plötzlich einen einzigen kleinen grünen Zweig zwischen den scheinbar verdorrten Stämmen und Ästen bemerkt.',
	},
	
	{ id: '75', 
	imageName: 'book/46- Kanaren, Lanzarote, Windspiel - Marz 2022.jpg',
	title: '46 - Kanaren, Lanzarote, Windspiel - März 2022',
	subtitle: 'Viel hat der Künstler und Architekt César Manrique für seine Heimatinsel Lanzarote getan. Er hat zum Beispiel verhindert, das Hotel-Betonburgen gebaut wurden. Er hat das Bild von Lanzarote entscheidend geprägt, hat seinen Baustil und seine Kunst in die Natur eingebettet, mit der Natur und nicht gegen die Natur gearbeitet. Er nutzte auch den stetigen Passatwind, der über die Inseln weht, für seine Skulpturen aus beweglichen Elementen, den Windspielen. Sie stehen in der Stadt oder auch mitten in der Landschaft und prägen das Bild von Lanzarote.',
	},
	
	{ id: '76', 
	imageName: 'book/47- Kapverden, Fogo, Cha das Calderas - Oktober 2017.jpg',
	title: '47 - Kapverden, Fogo, Cha das Calderas - Oktober 2017',
	subtitle: 'Mitten in der Caldera des Fogo-Vulkans gibt es ein kleines Dorf, Chã das Caldeiras. Hier wird Wein angebaut. 1995 brach der Vulkan einmal wieder aus und zerstörte die meisten Häuser und die Weinreben. 2014 begrub er während eines weiterer Ausbruchs 75 Prozent der noch stehenden oder wiederaufgebauten Häuser unter seinem Lavastrom. Die meisten Einwohner verließen den Ort, aber einige wenige bauten ihre Häuser erneut auf. Tausend Meter ragt der Rand der Caldera über dem Boden des Kraters auf. Darin der Vulkan Pico und der kleinere Pico Pequeno.',
	},

	{ id: '77', 
	imageName: 'book/48- Kapverden, Santiago, Darwin Aufschluss - November 2017.jpg',
	title: '48 - Kapverden, Santiago, Darwin Aufschluss - November 2017',
	subtitle: 'Der Erste, der sich mit der Geologie von Santiago beschäftigte, war kein Geringerer als Charles Darwin. Am 16. Januar 1832 ging die BEAGLE vor Porto Praia auf Santiago vor Anker. Auf der Insel Santa Maria macht Darwin seine ersten eigenen geologischen Beobachtungen.',
	},

	{ id: '78', 
	imageName: 'book/49- Kapverden, Santo Antao, Vallee des Dykes - Oktober 2019.jpg',
	title: '49 - Kapverden, Santo Antao, Vallee des Dykes - Oktober 2019',
	subtitle: 'Ein absolut traumhafter Ausflug in eine der landschaftlich schönsten Gegenden der Kapverdischen Inseln, in das Tal der Dykes. Kurz hinter Porto Novo liegen schneeweiße Hügel in der Landschaft. Es handelt sich um Puzzolan, ein sehr seltenes vulkanisches Gestein, das auf der Insel verarbeitet und als Zementersatz genutzt wird. Das Tal der Dykes kann durchfahren oder durchwandert werden und lockt mit fantastischen Felsformationen aus Basaltsäulen, Dykes, Lavaflüssen und bunten Aschelagen.',
	},

	{ id: '79', 
	imageName: 'book/50- Kolumbien, Cartagena - Marz 2014.jpg',
	title: '50 - Kolumbien, Cartagena - März 2014',
	subtitle: 'Vergessen Sie Drogenkartelle, Todesschwadrone, Mord und Gewalt. Cartagena ist eine bunte, fröhliche karibische Stadt mit wunderschönen kleinen Gassen voller farbenprächtiger Blumen und tropischen Bäumen. Jedes Haus scheint den Nachbarn übertreffen zu wollen, sei es mit seinen typischen Balkonen, der bunten Bemalung oder dem Blütenmeer aus Bougainvilleas und anderen farbenprächtigen Gewächsen. Dazu bietet Cartagena eine spannende Geschichte und einmalige historischen Persönlichkeiten mit skurrilen Lebensläufen.',
	},	
	
	{ id: '80', 
	imageName: 'book/51- Lettland, Riga, Jugenstilhauser - Juni 2013.jpg',
	title: '51 - Lettland, Riga, Jugenstilhäuser - Juni 2013',
	subtitle: 'Wo gibt es die schönsten Jugendstilhäuser, in Wien, Paris, Brüssel oder in Deutschland? Das schönste Ensemble dieser Epoche habe ich in zwei Straßen in Riga gefunden. Hier befindet sich die weltweit größte Ansammlung von Jugendstilarchitektur. Heute zählt man um die achthundert Gebäude im Jugendstil. Die schönsten sieht man bei einem Spaziergang durch die Elisabethstraße und die Alberta iela mit ihren prunkvollen Wohnhäusern.',
	},

	{ id: '81', 
	imageName: 'book/52- Madagascar, Nosy Lakandava - Dezember 2013.jpg',
	title: '52 - Madagascar, Nosy Lakandava - Dezember 2013',
	subtitle: 'Die Insel mit ihren felsigen Spitzen, gesäumt von glitzernden weißen Sandstränden und glasklarem Wasser ist atemberaubend. Eine langgezogene, verträumt anmutende Insel ohne Einwohner. Der schöne Sandstrand wird von Sandstein- und Kalkfelsen gesäumt, die durch die Erosion des Regenwassers tiefe und enge Schluchten, scharfe Kanten und Spitzen formen. Diese Formation wird in Madagaskar „Tsingy“ genannt. Am frühen Morgen bevölkern Einsiedlerkrebse den Strand. Auf den Kalkfelsen gedeihen Flammenbäume, Aloen und ein Elefantenfußgewächs mit prachtvollen roten Blüten.',
	},
	
	{ id: '82', 
	imageName: 'book/53- Madeira, Funchal, Rua de Santa Maria - Mai 2016.jpg',
	title: '53 - Madeira, Funchal, Rua de Santa Maria - Mai 2016',
	subtitle: 'Es gab einmal eine Zeit, in der kein Tourist die verfallene Rua de Santa Maria besuchte. Das ist seit 2011 vorbei. Seit dieser Zeit bemalen Künstler die Türen und Fenster der Häuser in den kopfsteingepflasterten engen Straßen. Die Haustüren wurden zum Kunstobjekt. Über zweihundert Hauseingänge sind so in Open-Air-Kunst umgewandelt worden. Auf der ohnehin schon engen Straße haben sich dutzende von Restaurants angesiedelt, die ihre Tische und Stühle auf dem unebenen Kopfsteinpflaster aufgestellt haben.',
	},
	
	{ id: '83', 
	imageName: 'book/54- Madeira, Wanderung Ponta do Sao Lourenco - Oktober 2019.jpg',
	title: '54 - Madeira, Wanderung Ponta do Sao Lourenco - Oktober 2019',
	subtitle: 'Das Ostkap auf Madeira unterscheidet sich grundlegend vom Rest der Insel. Auf der Halbinsel São Lourenço ist kaum etwas grün, hier wachsen keine Wälder und hier blühen nur wenige Blumen. Die vorherrschenden Farben sind Schwarz, Gelb, Orange und Rot. In der schroffen, wüstenartigen Umgebung dominiert die Geologie über Flora und Fauna. Gewaltige Steilküsten fallen senkrecht ins Meer hinab, vulkanische Aschen in allen Farben und schwarzgraue Lavaschichten beherrschen die Landschaft. Quer durch diese Vulkanbauten schlagen sich silbergraue, basaltische Dykes hindurch.',
	},
	
	{ id: '84', 
	imageName: 'book/55- Marokko, Erg Chebbi - Marz 2008.jpg',
	title: '55 - Marokko, Erg Chebbi - März 2008',
	subtitle: 'Die Dünenlandschaft der Sahara. Darin eine Kamelkarawane mit Touristen, auch ich bin dabei. Eigentlich mag ich so etwas gar nicht. Aber dies ist eine Ausnahme. Zum Sonnenuntergang erreicht man das Nachtlager mit den Zelten. Es gibt ein leckeres und einfaches Essen über dem Holzfeuer gekocht. Der Himmel ist sternenklar. Etwas abseits vom Lagerplatz, ohne jede Lichtverschmutzung, sitze ich auf einem Dünenkamm und bewundere die Aussicht ins All.',
	},
	
	{ id: '85', 
	imageName: 'book/56a- Marokko, Fez, Gerberviertel - Marz 2008.jpg',
	title: '56a - Marokko, Fez, Gerberviertel - März 2008',
	subtitle: 'Mitten in der Altstadt wird in einem Inferno von Gestank und schlimmen Arbeitsbedingungen Leder gegerbt und gefärbt. Nichts für schwache Nerven. Wie ein überdimensionaler Aquarellmalkasten sehen die mit Gerb- und Farbstoffen gefüllten Lehmbecken aus. Meist ohne Schutzkleidung wird das Leder mit Rinderurin und Taubenkot gewalkt und gestampft. Gefärbt wird das Leder dann mit bloßen Händen mit Färbemitteln wie Safran, Indigo oder Mohn. So archaisch mutet alles an, dass die UNESCO das Gerberviertel zum Welterbe erhoben hat.',
	},
	
	{ id: '86', 
	imageName: 'book/56b- Marokko, Fez, Gerberviertel - Marz 2008.jpg',
	title: '56b - Marokko, Fez, Gerberviertel - März 2008',
	subtitle: '',
	},

	{ id: '87', 
	imageName: 'book/57- Marokko, Marrakesch, Riad - Marz 2008.jpg',
	title: '57 - Marokko, Marrakesch, Riad - März 2008',
	subtitle: 'Möchten Sie einmal 1001 Nacht erleben? Nicht im Buch oder in einem Film, sondern tatsächlich? Dann sollten Sie in einem der vielen Riads in Marrakesch übernachten. Klein sind solche Riads, meist haben sie nur wenige Zimmer. Die meisten dieser ehemaligen Herrenhäuser liegen mitten im lärmumtosten Souk. Sobald man aber durch das meist unscheinbare Tor tritt, lässt man den Tumult hinter sich, und es kehrt eine unvorstellbare Ruhe ein. Im Innenhof plätschert ein Pool mit einer kleinen Fontäne, überall Blumen, Blüten und orientalisches Kunstgewerbe.',
	},

	{ id: '88', 
	imageName: 'book/58- Marokko, Todra Schlucht - Marz 2008.jpg',
	title: '58 - Marokko, Todra Schlucht - März 2008',
	subtitle: 'Die Todra-Schlucht liegt in Marokko im Atlasgebirge. Bis zu 300 Meter ragen die Steilwände der Schlucht in die Höhe. Am Boden zieht sich ein kleines Flüsschen und eine Straße entlang. Für mehr reicht der Platz nicht. Die Sonne erreicht die engste Stelle des Tals nur für eine knappe Stunde. Das eigentlich Faszinierende der Schlucht sind die Farbkontraste, Felswände, die je nach Sonnenstand schwarz, rot, orange oder gelb zu sein scheinen und an der Straße die kleinen Verkaufsstände mit den leuchtend bunten Textilien, meist im knalligen Gelb oder Blau.',
	},

	{ id: '89', 
	imageName: 'book/59a- Mexiko, Teotihuacan, Ballonfahrt - Marz 2017.jpg',
	title: '59a - Mexiko, Teotihuacan, Ballonfahrt - März 2017',
	subtitle: 'Es ist noch dunkel, als ich am Startplatz meiner Ballonfahrt eintreffe. Brüllend füllen die Feuerschleudern der Gasbrenner die Ballonhüllen mit heißer Luft, und langsam erheben sich die farbenprächtigen Kugeln in die Luft. Ich klettere in den Ballonkorb hinein, und langsam gewinnen wir unter dem Feuerstrahl des Brenners an Höhe. Die Sonne geht auf und beleuchtet eine Seite der riesigen Sonnenpyramide und der etwas kleineren Mondpyramide im Zentrum der aztekischen Ruinenstadt Teotihuacan.',
	},	
	
	{ id: '0', 
	imageName: 'book/59b- Mexico, Teotihuacan, Ballonfahrt - Marz 2017.jpg',
	title: '59b - Mexico, Teotihuacan, Ballonfahrt - März 2017',
	subtitle: '',
	},

	{ id: '91', 
	imageName: 'book/60- Namibia, Bogenfels - Oktober 1988 (Foto Wikipedia).jpg',
	title: '60 - Namibia, Bogenfels - Oktober 1988 (Bild Wikipedia)',
	subtitle: 'An der sturmumtosten Atlantikküste von Namibia, südlich des Oranje-Flusses, nahe der Grenze zu Südafrika liegt ein landschaftliches Juwel. Eine Besichtigung ist schwierig. Nur mit einer speziellen Genehmigung gelangt man dorthin. Der 55 Meter hohe Bogenfels, ein mächtiges Felsentor aus Kalkstein zwischen Meer und Strand, liegt nämlich im Diamantsperrgebiet. In der Elisabeth-Bucht ragt der Fels majestätisch in den Himmel. Ende der achtziger Jahre hatte ich einmal die Gelegenheit, schwer bewacht von zwei Soldaten der Diamantenpolizei, dieses geologische Wüstenmonument zu besichtigen.',
	},
	
	{ id: '92', 
	imageName: 'book/61- Namibia, Fishriver Canyon - 1988 (Foto Calpips).jpg',
	title: '61 - Namibia, Fishriver Canyon - 1988 (Bild Calpips)',
	subtitle: "Es war für viele junge Menschen in den achtziger Jahren fast Kult, in Namibia mit dem laut aufgedrehten Lied „Hymn“ von Barclay James Harvest mit einer Dose Bier in der linken- und einem Joint in der rechten Hand am Rand des Fishriver-Canyons zu stehen, m den Sonnenuntergang zu bewundern. Und völlig zugedröhnt versuchten einige trotz der Warnung im Text des Liedes zu fliegen. Gut, dass ich keinen Joint, sondern nur eine Dose Bier in der Hand hatte.",
	},
	
	{ id: '93', 
	imageName: 'book/62- Namibia, Kolmannkop - Mai1987 (Foto Wikipedia).jpg',
	title: '62 - Namibia, Kolmannkop - Mai1987 (Bild Wikipedia)',
	subtitle: 'Kolmanskop ist eine aufgegebene Geisterstadt in Namibia, ungefähr zehn Kilometer östlich der Hafenstadt Lüderitz. 1908, als Namibia noch Deutsch-Südwestafrika war, fand der Deutsche August Stauch am damaligen Eisenbahn-Bahnhof Grasplatz zufällig einen großen Diamanten im Wüstensand. Ein unglaublicher Boom begann, und so wurde in der eigentlich trostlosen und lebensfeindlichen Wüste mit ihren Sandstürmen eine luxuriöse Bergbaustadt für bis zu vierhundert Menschen erschaffen.',
	},
	
	{ id: '94', 
	imageName: 'book/63- Namibia, Spitzkopje - Mai 1987 (Bild Wikipedia).jpg',
	title: '63 - Namibia, Spitzkopje - Mai 1987 (Bild Wikipedia)',
	subtitle: 'Alleine draußen in der Namib am Fuße des Granitmonolithen der Spitzkopje übernachten, eindringlicher kann man die Wüste wohl kaum erleben. Man braucht einen Jeep oder Ähnliches um hierherzugelangen. Auf dem Weg zu meinem Rastplatz sammle ich in den Trockenflüssen verdorrtes Holz, binde es mit einem Seil an meinen Jeep und ziehe es hinter mir her bis zum Rastplatz. Es ist nur noch eine Stunde bis zum Sonnenuntergang.',
	},
	
	{ id: '95', 
	imageName: 'book/64a- Norwegen, Bergen - Juli 2010.jpg',
	title: '64a - Norwegen, Bergen - Juli 2010',
	subtitle: 'Der Fischmerkt von Bergen ist ein echten Touristenmagnet. Alles was aus dem Meer kommt, wird hier verkauft, kann verköstigt und an den unzähligen Ständen gegessen werden. Dazu gehört auch Walfleisch, geräuchert oder gebraten als Steak. Einmal habe ich ein Stück probiert. Leicht traniges Rindfleisch. Darauf kann man ohne Weiteres verzichten. Die Verkäufer an den Ständen kommen von überallher, nur nicht aus Norwegen.',
	},
	
	{ id: '96', 
	imageName: 'book/64b- Norwegen, Bergen - Juli 2010.jpg',
	title: '64b - Norwegen, Bergen - Juli 2010',
	subtitle: '',
	},

	{ id: '97', 
	imageName: 'book/65- Norwegen, Oslo, Oper - August 2014.jpg',
	title: '65 - Norwegen, Oslo, Oper - August 2014',
	subtitle: 'Wer gerne einmal auf einem Hausdach spazierengehen möchte und dabei einen Panoramablick auf eine Fjordlandschaft mit kleinen, bunt gestrichenen Holzhäusern auf der einen Seite und einer pulsierenden Großstadt auf der anderen Seite genießen möchte, der ist auf dem Marmordach der Osloer Oper gut aufgehoben. Das Dach, dass in einer leichten Schräge direkt im Meer verschwindet, ist einem treibenden Eisberg nachempfunden. Das architektonische Meisterwerk aus Marmor und Granit ist Norwegens größtes Kulturprojekt der Nachkriegszeit.',
	},

	{ id: '98', 
	imageName: 'book/66a- Panama, Panama City, Altstadt - Mai 2015.jpg',
	title: '66a - Panama, Panama City, Altstadt - Mai 2015',
	subtitle: 'Es gibt wohl kaum eine Stadt, die solch einen Kontrast zu bieten hat, wie Panama City. Auf der einen Seite die Altstadt mit ihren gepflasterten Gassen und kleinen Geschäften. Auf den Plätzen der Stadt blühen üppige Bougainvilleas, Cafés und kleine Bars an jeder Ecke. Und überall Läden, die die berühmten Panamahüte verkaufen, die übrigens alle nicht aus Panama, sondern aus Ecuador kommen. All das sucht man vergebens auf der anderen Seite der Stadt, der Neustadt mit ihren hypermodernen Hochhäusern, die sowohl deren Architekten als auch die darin etablierten Banken unendlich reich machen.',
	},

	{ id: '99', 
	imageName: 'book/66b- Panama City, Gehry Building - Mai 2015.jpg',
	title: '66b - Panama City, Gehry Building - Mai 2015',
	subtitle: '',
	},	
	
	{ id: '100', 
	imageName: 'book/66c- Panama, Panama City - Marz 2017.jpg',
	title: '66c - Panama, Panama City - März 2017',
	subtitle: '',
	},

	{ id: '101', 
	imageName: 'book/67a- Panama, St. Blas Inseln - Mai 2015.jpg',
	title: '67a - Panama, St. Blas Inseln - Mai 2015',
	subtitle: 'Ein karibischer Archipel aus Korallenriffen, mit über 300 Inseln, die durch den Anstieg des Meeresspiegels dem Untergang geweiht sind. Heimat der Kuna Yana. Die Gemeinschaft lebt im Matriarchat hauptsächlich vom Fischfang. Weltweit bekannt sind die Kunas für die Anfertigung ihrer Molas. Die Molas wurden ursprünglich auf Vorder- und Rückseite der Blusen der Kuna-Frauen genäht. Bei der Herstellung werden mehrere bunte Lagen von Stoffen übereinander vernäht.',
	},
	
	{ id: '102', 
	imageName: 'book/67b- Panama, St. Blas Inseln - Mai 2016.jpg',
	title: '67b - Panama, St. Blas Inseln - Mai 2016',
	subtitle: '',
	},
	
	{ id: '103', 
	imageName: 'book/68- Peru, Arequipa, El Misti - Februar 1980.jpg',
	title: '68 - Peru, Arequipa, El Misti - Februar 1980',
	subtitle: 'Auch Misserfolge können zu den unvergesslichen Reiseerinnerungen zählen. So war es mir ergangen, als ich als Student auf einer Rucksackreise durch Peru versuchte, den majestätischen Vulkankegel des El Misti mit einer Höhe von 5822 Meter zu besteigen. Obwohl ich mich schon fast einen Monat in Höhen über 4000 Metern aufhielt, musste ich 200 Meter unterhalb des Gipfels umkehren.',
	},
	
	{ id: '104', 
	imageName: 'book/69- Peru, Cuzco - Marz 2017.jpg',
	title: '69 - Peru, Cuzco - März 2017',
	subtitle: 'Nur ganz langsam, Schritt für Schritt, kann man Cuzco erkunden. Die Stadt liegt auf einer Höhe von 3400 Metern und ist im wahrsten Sinne des Wortes atemberaubend. Cuzco war einmal die prachtvolle Hauptstadt des Inkareiches, bevor die spanischen Konquistadoren die Stadt zerstörten und auf den Ruinen ihre eigenen Prachtbauten errichteten. Dieses Nebeneinander macht den Reiz der Stadt aus. Der Zentralplatz wird von den mächtigen spanischen Kirchenbauten beherrscht.',
	},
	
	{ id: '105', 
	imageName: 'book/70- Peru, Islas Ballestas - Mai 2014.jpg',
	title: '70 - Peru, Islas Ballestas - Mai 2014',
	subtitle: 'Ein Vogelparadies der ganz besonderen Art. Die Inseln vor der peruanischen Küste bei Paracas sind das Brutgebiet von Pelikanen, Möwen, Kormoranen und den Guanotölpeln. Seit dem 19. Jahrhundert wird hier bis heute der Guano, der ätzende Vogelkot, unter schlimmsten Arbeitsbedingungen abgebaut. Heute können die Touristen mit Schnellbooten zu den Inseln fahren und die unglaublichen Vogelscharen bewundern. Ständig fliegen die Tiere von den Felsen aufs Meer hinaus, um im waghalsigen Sturzflug Fische zu fangen und zurück zu ihren Nestern mit den dort wartenden Jungvögeln zu bringen.',
	},
	
	{ id: '106', 
	imageName: 'book/71- Peru, Maras - Oktober 2010.jpg',
	title: '71 - Peru, Maras - Oktober 2010',
	subtitle: 'Wenn wir schon in Peru sind, wo bleibt Machu Picchu, mag man sich fragen. Dieser Ort ist so bekannt, dass ich ihn hier einfach nicht erwähne. Aber auf dem Weg nach Machu Picchu gibt es einen ganz besonderen Platz und das ist Maras. Über eine staubige und kurvenreiche Piste erreicht man die in einer Bergschlucht versteckt liegenden Salzterrassen von Maras. Hier bauten schon die Inka Salz ab. Aus einer kleinen Quelle plätschert das Salzwasser durch salzverkrustete kleine Kanäle in etwa 4000 kleine Salzbecken. Baumaterial ist Lehm und Salz.',
	},

	{ id: '107', 
	imageName: 'book/72- Peru, Nazca - Marz 2012.jpg',
	title: '72 - Peru, Nazca - März 2012',
	subtitle: 'Schnurgerade Linien, merkwürdige Dreiecke, Spiralen und andere geometrische Formen und riesige Tiere, Kolibri, Kondor, Affe, Hund, Spinne und Wal. Ein merkwürdiger Astronaut und Hände mit vier oder fünf Fingern. Die Scharrbilder der Nazca Kultur kann man sich am besten bei einem Rundflug mit kleinen, wendigen Propellermaschinen aus der Luft ansehen. Zu groß sind die Figuren, um sie vom Boden aus wahrzunehmen.',
	},

	{ id: '108', 
	imageName: 'book/73- Portugal, Porto, Pasteis de Nata - April 2014.jpg',
	title: '73 - Portugal, Porto, Pastéis de Nata - April 2014',
	subtitle: 'Eigentlich mag ich Lissabon nicht besonders. Aber im Stadtteil Belém, einfach mit der Straßenbahn zu erreichen, bin ich immer wieder gerne. Es wimmelt auch hier von Touristen, aber im Gelände zwischen dem Kloster Jerónimo, dem Torre de Belém (das Wahrzeichen von Lissabon) und dem Seefahrerdenkmal verläuft sich alles ein wenig. Es gibt noch einen Grund, warum ich gerne hierherkomme. Hier gibt es die Pastelaria de Belém. Die Bäckerei gilt als Geburtsstätte der Pastéis de Nata, Portugals Beitrag zum kulinarischen Welterbe. Hier gibt es die originalen und einmalig leckeren warmen Pastéis de Nata.',
	},

	{ id: '109', 
	imageName: 'book/74- Portugal, Porto - September 2014.jpg',
	title: '74 - Portugal, Porto - September 2014',
	subtitle: 'Wenn ich die Wahl hätte, in Portugal entweder Porto oder Lissabon zu besuchen, dann würde ich mich immer für Porto entscheiden. Die Stadt ist an den steilen Hängen über dem Douro erbaut. Vielstöckige bunte Häuser, fast alle mit schönen Balkonen, aber auch fast alle extrem renovierungsbedürftig und dem Verfall nahe. Enge kleine Gassen führen durch das Altstadtviertel hinunter an den Fluss. Dort haben die großen Portweinkellereien ihren Sitz. Stundenlang kann man durch die Altstadt laufen und entdeckt immer wieder neue kleine Restaurants, Cafés, schönes Kunstgewerbe und natürlich die Geschäfte mit dem Portwein.',
	},	
	
	{ id: '110', 
	imageName: 'book/75- Portugal, Porto, Irrgarten - Marz 2019.jpg',
	title: '75 - Portugal, Porto, Irrgarten - März 2019',
	subtitle: 'Etwas außerhalb der Innenstadt von Porto gibt es ein kaum bekanntes Kloster. Sollte man tatsächlich bis dahin kommen, dann erwartet den Besucher im Klostergarten ein wunderschöner Irrgarten aus Buchsbaumhecken. Ein freundlicher Portier lässt die Gäste hinein und drückt jedem eine Karte des Irrgartens in die Hand. Ziel ist es, einmal bis zum Zentrum zu gelangen und dann auch wieder hinaus. Und eben für dieses Hinausgelangen ist die Karte.',
	},

	{ id: '111', 
	imageName: 'book/76- Russland, Peterhof - Juni 2014.jpg',
	title: '76 - Russland, Peterhof - Juni 2014',
	subtitle: 'Ich zitiere aus Wikipedia: „Das Schloss Peterhof ist eine ehemalige Sommerresidenz der russischen Zaren am Finnischen Meerbusen in Peterhof, etwa 25 km westlich von Sankt Petersburg. Die Anlage entstand nach dem architektonischen Vorbild von Schloss Versailles und nach eigenhändigen Entwürfen Peters des Großen. Schloss und Park wurden in mehreren Abschnitten von 1715 bis 1755 im Stil des Barocks erbaut. Im Zweiten Weltkrieg wurde Peterhof von der deutschen Wehrmacht zerstört und danach von sowjetischen Restauratoren wiederaufgebaut.“',
	},
	
	{ id: '112', 
	imageName: 'book/77- Russland, St. Petersburg, Kanalfahrt - August 2014.jpg',
	title: '77 - Russland, St. Petersburg, Kanalfahrt - August 2014',
	subtitle: 'Eine nächtliche Kanalfahrt auf der Newa sollte man sich in Sankt Petersburg nicht entgehen lassen. Die Tour beginnt kurz vor Sonnenuntergang und endet im Dunkeln. Auf beiden Seiten der Newa kann man die prachtvollen, teilweise angestrahlten Bauwerke bestaunen. Schlossplatz, Ermitage, Isaaks Kathedrale, Admiralität, Winterpalais, Peter-und-Paul-Festung, das Schlachtschiff AURORA, Blutkirche und viele andere.',
	},
	
	{ id: '113', 
	imageName: 'book/78- Schottland, Fair Isle - Mai 2019.jpg',
	title: '78 - Schottland, Fair Isle - Mai 2019',
	subtitle: 'Papageitaucher gibt es auch hier zu sehen wie an einigen anderen meiner Lieblingsplätze. Aus nächster Nähe und an einem Hügel direkt am kleinen Hafen kann man die Tiere bequem beobachten. Da sich auch viele andere Vögel die kleine Shetland-Insel als Brutquartier ausgesucht haben, wird die Insel auch als Vogelinsel bezeichnet. Aber nicht nur als Vogelinsel, sondern auch als Pullover-Insel.',
	},
	
	{ id: '114', 
	imageName: 'book/79- Schottland, Skye, Old man of storr - Mai 2029.jpg',
	title: '79 - Schottland, Skye, Old man of storr - Mai 2029',
	subtitle: 'Auf der Insel Skye haben sich viele Künstler und Lebenskünstler niedergelassen. Hier wird unter anderem aus den Kräutern und Beeren, die auf der Insel vorkommen, ein fantastischer Gin mit dem Namen Misty Isle destilliert. Die Weberei Skye Weavers stellt wunderbare Kleidungsstücke her. Eine weitere Künstlerin, Diana Mackie, versucht auf ihren Ölbildern die Mystik der Insel einzufangen. Wahrzeichen der Insel ist eine Felsformation direkt an der Küstenstraße.',
	},
	
	{ id: '115', 
	imageName: 'book/80a- Schottland, Aussere Hebriden, Staffa, Fingals Cave - Mai 2019.jpg',
	title: '80a - Schottland, Äussere Hebriden, Staffa, Fingals Cave - Mai 2019',
	subtitle: 'Die Fingals Cave an der schottischen Küste ist das Gegenstück zum Giant Causeway in Nordirland. Als Schottland und Irland vor geologisch langer Zeit noch zusammengehörten, erstreckte sich hier ein riesiger Lavastrom, der die wundersamen sechseckigen Basaltsäulen formte. Während man am Giant Causeway auf den Basaltsäulen spazierengehen kann, kann man auf Staffa mit einem kleinen Zodiac bei ruhiger See in eine Höhle, die die Brandung aus den Basaltsäulen herausgeschlagen hat, hineinfahren.',
	},
	
	{ id: '116', 
	imageName: 'book/80b- Schottland, Aussere Hebriden Staffa, Fingals Cave - Juni 2015.jpg',
	title: '80b - Schottland, Äussere Hebriden Staffa, Fingals Cave - Juni 2015',
	subtitle: '',
	},

	{ id: '117', 
	imageName: 'book/81- Schweden, Jukkasjarvi, Eishotel - Januar 2009.jpg',
	title: '81 - Schweden, Jukkasjärvi, Eishotel - Januar 2009',
	subtitle: 'Ein Eishotel ist ein Hotel, das nur aus Eis und Schnee erbaut ist. Seit 1989 gibt es so etwas in Jukkasjärvi, und seitdem wird es jedes Jahr im Winter neu gebaut, erweitert und verschönert. Es gibt sechzig Schlafräume, eine Rezeption, eine Bar, ein Kino und eine Kirche. Die Räume sind eingerichtet mit zum Teil von innen beleuchteten Skulpturen, die von Eisbildhauern und Künstlern aus aller Welt angefertigt werden. Übernachten kann man hier nur im Winter. Im Sommer taut die ganze Pracht einfach weg.',
	},

	{ id: '118', 
	imageName: 'book/82a- Schweden, Stockholm, Jarnpojken - August 2009.jpg',
	title: '82a - Schweden, Stockholm, Järnpojken - August 2009',
	subtitle: 'In der Altstadt von Stockholm, der Gamla Stan, gibt es etwas Winzigkleines zu bewundern. Bei der finnischen Kirche hockt die kleinste Skulptur Stockholms „Järnpojken“ (= sitzendes Kind), gerade einmal fünfzehn Zentimeter groß. Im Sommer oft mit Blumen im Arm. Die Anwohner dieser Gegend kümmern sich liebevoll um ihren „Järnpojken“. Daher ist das Järnpojken dann im Winter auch warm angezogen, mit Pudelmütze und Schal. Es wird gesagt, der Junge habe magische Kräfte.',
	},

	{ id: '119', 
	imageName: 'book/82b- Schweden, Stockholm, Jarnpojken - Januar 2009.jpg',
	title: '82b - Schweden, Stockholm, Järnpojken - Januar 2009',
	subtitle: '',
	},	
	

	{ id: '120', 
	imageName: 'book/83- Schweden, Stockholm, Saluhall - Januar 2009.jpg',
	title: '83 - Schweden, Stockholm, Saluhall - Januar 2009',
	subtitle: 'Es gibt viel zu sehen in Schwedens Hauptstadt Stockholm. Mir gefallen besonders zwei nicht so bekannte Plätze. Seit 1888 gibt es die Östermalm Saluhall in Stockholm. Hier treffen sich Einheimische genauso gerne wie versierte Touristen. Man kann alle Edelprodukte der schwedischen und der internationalen Küche bekommen. Dazu kommen einige Restaurants, wo man die Köstlichkeiten gleich probieren kann. Eines der bekanntesten Restaurants für Fisch und Meeresfrüchte ist der „Laden Nr. 21“ von Lisa Elmquist.',
	},

	{ id: '121', 
	imageName: 'book/84a- Schweden, Stora Dyron - Oktober 2020.jpg',
	title: '84a - Schweden, Stora Dyrön - Oktober 2020',
	subtitle: 'Stora Dyrön ist eine Insel im Schärengarten von Bohuslän, die zum Wandern einlädt. Die spannendste Stelle der fünf Kilometer langen Rundwanderung ist die Schlucht Dyne, ein Riss in einer Felswand, an der schmalsten Stelle gerade mal sechzig Zentimeter breit. Dem Wanderer bieten sich großartige Ausblicke von den Bergkuppen über die Insel Åstol, einen Leuchtturm, den Ort selbst und die Burg Marstrand. Mit etwas Glück kann man auch eines der hier wild lebenden Mufflon-Schafe beobachten. Mitten auf dem Wanderweg liegt eine Sauna, die man im Voraus buchen kann.',
	},
	
	{ id: '122', 
	imageName: 'book/84b- Schweden, Stora Dyron - Oktober 2020.jpg',
	title: '84b - Schweden, Stora Dyrön - Oktober 2020',
	subtitle: '',
	},
	
	{ id: '123', 
	imageName: 'book/85a- Slowakei, Bratislava - Juni 2011.jpg',
	title: '85a - Slowakei, Bratislava - Juni 2011',
	subtitle: 'Ein Spaziergang durch die Straßen von Bratislava ist wie ein Besuch eines Skulpturenmuseums. An jeder Ecke wird man von einer neuen, oft lustigen Skulptur überrascht. Da schaut eine Bronzefigur aus einem Gully heraus und ein napoleonischer Soldat lehnt sich müde an die Rücklehne einer Sitzbank in der Altstadt. Den schönen Náci kannte jeder, der in Bratislava wohnte. Er lief stets in Frack, Lackschuhen und Zylinder durch die Stadt und sprach weibliche Passanten mit den Worten „Küss die Hand gnädige Frau“ an. Oft überreichte er dann auch noch eine Blume. Auch ihn finden wir als Bronzefigur.',
	},
	
	{ id: '124', 
	imageName: 'book/85b- Slowakai, Bratislava - Juni 2011.jpg',
	title: '85b - Slowakai, Bratislava - Juni 2011',
	subtitle: '',
	},
	
	{ id: '125', 
	imageName: 'book/86- Sudgeorgien, St. Andrews Bay - Dezember 2014.jpg',
	title: '86 - Südgeorgien, St. Andrews Bay - Dezember 2014',
	subtitle: 'Die größte Kolonie von Königspinguinen weltweit. Schwieriger Anmarsch inklusive Flussdurchquerung. Erklimmen einer Endmoräne und dann der Blick auf hunderttausende dieser wunderschönen Tiere, die zwischen dem Meer, verschneiten Bergen und einem Gletscherbach im Tal brüten. Mehr muss man zu Saint Andrews Bay nicht sagen. Der Platz spricht einfach für sich selbst.',
	},
	
	{ id: '126', 
	imageName: 'book/87a- Spanien, Bilbao, Maman - Oktober 2016.jpg',
	title: '87a - Spanien, Bilbao, Maman - Oktober 2016',
	subtitle: 'Ein Museum das, jedenfalls für mich, von außen noch beeindruckender ist als von innen. Der kanadische Architekt Frank Gehry hatte mit seinem unkonventionellen architektonischen Stil des Dekonstruktivismus Aufsehen erregt. Aber das Guggenheim-Museum von Bilbao sollte alles Vorherige in den Schatten stellen. Ein Gebäude ohne Ecken, ohne Geraden, nur Wellen, Bögen und Kurven, und das alles scheinbar chaotisch ineinander verschachtelt.',
	},

	{ id: '127', 
	imageName: 'book/87b- Spanien, Bilbao, MOMA - Oktober 2016.jpg',
	title: '87b - Spanien, Bilbao, MOMA - Oktober 2016',
	subtitle: '',
	},

	{ id: '128', 
	imageName: 'book/88- Spanien, Navajun, Pyrit - Oktober 2016.jpg',
	title: '88 - Spanien, Navajún, Pyrit - Oktober 2016',
	subtitle: 'Weltweit bekannt, zumindest unter Mineralogen, ist Navajún für seine Pyritmine, die 1960 durch den Bergmann Pedro Ansorena entdeckt wurde. Hier werden perfekte Pyritwürfel gefördert, oder man kann sie gegen eine kleine Gebühr selbst sammeln. Es werden Pyrite mit einer Kantenlänge von bis zu sechs Zentimeter gefunden.',
	},

	{ id: '129', 
	imageName: 'book/89- Spitzbergen, Barentsburg - August 2015.jpg',
	title: '89 - Spitzbergen, Barentsburg - August 2015',
	subtitle: 'Barentsburg lebte einmal vom Kohlenbergbau, heute aber immer mehr vom Tourismus. Die wenigen Einwohner, einige hundert, kommen aus Russland und aus der Ukraine. Während einer Ortserkundung sieht man unter anderem die kleine russisch-orthodoxe Kapelle, natürlich ein Lenindenkmal, ein großes Indoor-Schwimmbad ohne Wasser und als absolutes Highlight die örtliche Brauerei, wo man sich, wie es sich in Russland gehört, ein Bier oder einen echten russischen Wodka genehmigen sollte.',
	},	

	{ id: '130', 
	imageName: 'book/90- Spitzbergen, Bockfjord - Juli 2014.jpg',
	title: '90 - Spitzbergen, Bockfjord - Juli 2014',
	subtitle: 'Am Bockfjord gibt es eine Stelle mit den Jotunkildene, den Quellen Jotuns. Benannt sind sie nach einem Riesen der nordischen Mythologie. Die Ursache für das Vorhandensein der warmen Quellen an dieser Stelle ist ein erloschener Vulkan, der vor wenigen 100.000 Jahren wahrscheinlich unter dem Gletschereis ausgebrochen ist. Vom Vulkan ist durch die Einwirkung der Gletscher nur noch wenig zu erkennen, aber die relativ junge vulkanische Aktivität macht sich in Form der warmen Quellen bemerkbar, die immerhin eine Wassertemperatur von achtzehn bis zwanzig Grad haben.',
	},

	{ id: '131', 
	imageName: 'book/91a- Spitzbergen, Wanderung Lillehook - Juni 2018.jpg',
	title: '91a - Spitzbergen, Wanderung Lillehöök - Juni 2018',
	subtitle: 'Am Lilliehöökfjord ist der Startpunkt für eine abenteuerliche Wanderung. Knapp zehn Kilometer geht es über unwegsames Gelände durch eine faszinierende polare Landschaft. Gleich zu Beginn muss eine Steigung mit Schwemmsand und Lehm durchquert werden. Wer nicht genau aufpasst, wo er hintritt, steckt bald bis zu den Knien im Matsch fest und hat große Mühe, sich aus der Umklammerung des mit Wasser vollgesogenen Bodens wieder zu befreien. Die Wanderung ist dann meist schon zu Ende, bevor sie richtig beginnt.',
	},
	
	{ id: '132', 
	imageName: 'book/91b- Spitzbergen Wanderung Lillihoog - Juni 2018.jpg',
	title: '91b - Spitzbergen Wanderung Lillihöög - Juni 2018',
	subtitle: '',
	},
	
	{ id: '133', 
	imageName: 'book/92- Spitzbergen, Poolepynten - Juni 2019.jpg',
	title: '92 - Spitzbergen, Poolepynten - Juni 2019',
	subtitle: 'Poolepynten ist eine weite, flache Halbinsel auf der Ostseite von Prins Karls Forland, einer langen, schmalen Insel vor der Westküste von Spitzbergen. Die Uferverhältnisse sind für einfache Zodiac-Anlandungen gut geeignet. Bei Poolepynten befindet sich eine Walross-Kolonie, und meistens liegen einige dieser mächtigen Tiere am Ufer, andere tummeln sich im Wasser. Es herrscht ein ständiges Kommen und Gehen zwischen Land und Meer. Nähert man sich den liegenden Tieren gegen den Wind, kann man aus sicherem Abstand die Tiere wunderbar beobachten.',
	},
	
	{ id: '134', 
	imageName: 'book/93a- Sudsee, Cookinslen, Aitutaki - November 2016.jpg',
	title: '93a - Südsee, Cookinslen, Aitutaki - November 2016',
	subtitle: 'Aitutaki ist ein Südsee-Atoll. Die Insel befindet sich am Rande einer großen Lagune, zusammen mit fünfzehn kleineren Inseln, drei davon aus vulkanischem Gestein und zwölf Koralleninseln. Eine dieser kleinen Koralleninseln konnte ich einmal besuchen. Unser Boot ankerte einige hundert Meter von der Insel entfernt. Die restliche Wegstrecke muss man zu Fuß zurücklegen. Das Wasser reicht maximal bis zur Hüfte, ist herrlich lauwarm und voller kleiner, bunter Fische. Der Sand am Strand ist schneeweiß, die Kokospalmen ragen mit ihren Kronen über den Strand hinaus ins Wasser.',
	},
	
	{ id: '135', 
	imageName: 'book/93b- Sudsee, Cookinslen, Aitutaki - November 2018.jpg',
	title: '93b - Südsee, Cookinslen, Aitutaki - November 2018',
	subtitle: '',
	},
	
	{ id: '136', 
	imageName: 'book/94a- Sudsee, Marquesas Fatu Hiva - November 2019.jpg',
	title: '94a - Südsee, Marquesas Fatu Hiva - November 2019',
	subtitle: 'Auf der Insel Fatu Hiva gibt es eine Straße, die die beiden Örtchen Omoa und Hanavave miteinander verbindet. Die Strecke ist fünfzehn Kilometer lang. Dabei geht es zunächst einmal sieben Kilometer bergauf, von null auf 630 Meter hoch, und dann acht Kilometer wieder bergab auf Meereshöhe. Um diese Strecke zu bewältigen, gibt es zwei Möglichkeiten. Einen Jeep oder eben zu Fuß. Eine kleine Gruppe mit mir inklusive hat sich für die Wanderung entschieden.',
	},

	{ id: '137', 
	imageName: 'book/94b- Sudsee, Marquesas, Fatu Hiva - November 2019.jpg',
	title: '94b - Südsee, Marquesas, Fatu Hiva - November 2019',
	subtitle: '',
	},

	{ id: '138', 
	imageName: 'book/95- Syrien, Bosra - Oktober 2010.jpg',
	title: '95 - Syrien, Bosra - Oktober 2010',
	subtitle: 'Der markanteste Bau in der Stadt ist das berühmte Theater von Bosra, das unter den Römern im 3. Jahrhundert n. Chr. aus dunkelgrauem Basalt erbaut wurde. Es fasste 15.000 Zuschauer und besitzt eine solche Akustik, dass geflüsterte Worte auf der Bühne von jedem Zuhörer gleichermaßen verstanden werden konnten. Bis zum Ausbruch des Bürgerkrieges fanden in der Römischen Arena regelmäßig Konzerte statt. Es war ein syrischer Musikertraum, hier vor dem antiken Halbrund zu stehen und zu spielen oder zu singen. Was für eine Kulisse, 102 Meter im Durchmesser vor den steil hinaufragenden steinernen Sitzreihen, die 15.000 Zuschauern Platz bieten.',
	},

	{ id: '139', 
	imageName: 'book/96a- Syrien, Palmyra - Oktober 2010.jpg',
	title: '96a - Syrien, Palmyra - Oktober 2010',
	subtitle: 'Kurz bevor es wegen des Bürgerkrieges für Touristen unmöglich wurde, Syrien zu besuchen, hatte ich das unglaubliche Glück, eine Syrien-und-Jordanien-Reise zu begleiten. Palmyra war der absolute Höhepunkt der Reise. Um den Zauber von Palmyra zu erleben, muss man sich noch vor Sonnenaufgang zu den Ruinen begeben. Was dann kommt, ist Gänsehaut pur. Palmyra liegt in einer Oase inmitten der syrischen Wüste, etwa zweihundert Kilometer nordöstlich von Damaskus. Man weiß gar nicht, was einen mehr in Bann zieht, der Baal-Tempel, das Theater oder die Grabtürme.',
	},	
	
	{ id: '140', 
	imageName: 'book/96b- Syrien Palmyra - Oktober 2010.jpg',
	title: '96b - Syrien Palmyra - Oktober 2010',
	subtitle: '',
	},

	{ id: '141', 
	imageName: 'book/97- Trinidad & Tobago, Scharlachsichler - Mai 2015.jpg',
	title: '97 - Trinidad & Tobago, Scharlachsichler - Mai 2015',
	subtitle: 'Es gibt für den Touristen nur einen Grund, um die Caroni-Sümpfe in Trinidad aufzusuchen. Der Scharlachsichler. Ein rubinroter Ibis. Erhebt sich ein Schwarm dieser stolzen Vögel in die Luft und dreht als rote Punktewolke eine Runde vor der smaragdgrünen Kulisse des üppigen Regenwaldes, stockt jedem Zuschauer der Atem. Die beste Zeit, um den Roten Ibis zu beobachten ist der späte Nachmittag kurz vor der Dämmerung, wenn die Schwärme von ihren etwa zwanzig Kilometer entfernten Futtergründen in Venezuela zurückkehren.',
	},
	
	{ id: '142', 
	imageName: 'book/98a- Tristan da Cunha - Dezember 2013.jpg',
	title: '98a - Tristan da Cunha - Dezember 2013',
	subtitle: 'Die Insel Tristan da Cunha im Südatlantik ist der einsamste Ort der Welt. Die Vulkaninsel im Südatlantik gilt nämlich als entlegenster bewohnter Ort der Erde. Der nächste Landfleck ist die Insel Sankt Helena, 2430 Kilometer entfernt. Anlanden? Meistens unmöglich durch das raus Klima, Wind und Wellen. Massentourismus ist auf Tristan da Cunha mit etwa zweihundert Einwohnern unbekannt. Die meisten Bewohner stammen allesamt von den damaligen Zuwanderern ab. An Land tummeln sich Felsenpinguine, Moorhühner oder Gelbnasen-Albatrosse. Im Meer schwimmen Barten-, Pott- und Buckelwale sowie Orcas und Delfine.',
	},
	
	{ id: '143', 
	imageName: 'book/98b- Tristan da Cunha - Dezember 2013.jpg',
	title: '98b - Tristan da Cunha - Dezember 2013',
	subtitle: '',
	},
	
	{ id: '144', 
	imageName: 'book/99- USA, Utha, Antelope Canyon - Oktober 1998.jpg',
	title: '99 - USA, Utha, Antelope Canyon - Oktober 1998',
	subtitle: 'Als wir nach einer langen Fahrt den Antelope Canyon erreichen, sind wir allein. Kein Auto kein Mensch. Nur ein einziger einsamer Aufpasser begrüßt uns und kassiert das Eintrittsgeld. Von einem Canyon ist nichts zu sehen. Dann entdecken wir einen schmalen Riss an der Erdoberfläche und einen winzigen und engen Pfad, der uns in den Canyon hinabführt. An der Oberfläche ist er an einigen Stellen so schmal, dass man sich breitbeinig darüberstellen kann. Am Boden erweitert sich der Riss mal auf drei Meter, mal verengt er sich auf sechzig Zentimeter, man muss sich regelrecht durch die Sandsteinwände hindurchzwängen, um weiterzukommen.',
	},
	
	{ id: '145', 
	imageName: 'book/100- USA, Bryce Canon - Oktober 1998 (Wikipedia).jpg',
	title: '100 - USA, Bryce Canon - Oktober 1998 (Bild Wikipedia)',
	subtitle: 'Ein Meer von Mauern, Pyramiden, Türmen, Phallen, Hügeln, Stangen und Titanen aus rotem, gelbem, weißem oder orangefarbenem Sandstein. Das ist der Bryce Canyon. Ein Amphitheater, ein geologisches Wunderland. Es gibt einfach nicht genug Superlative um diesen Canyon, der eigentlich gar kein Canyon ist, zu beschreiben. Es gibt unzählige Wanderrouten durch den Park.',
	},
	
	{ id: '146', 
	imageName: 'book/101- Usbekistan, Chiwa - Mai 2011.jpg',
	title: '101 - Usbekistan, Chiwa - Mai 2011',
	subtitle: 'Chiwa mit seinem historischen, kleinen Stadtkern ist seit 1990 UNESCO-Welterbe. Prachtvolle, orientalischen Bauwerke mit leuchtenden Mosaiken erzählen von der Blüte früherer Zeiten. Chiwa war einmal eine der wichtigsten Städte entlang der alten Seidenstraße, die auch durch Usbekistan führte und eine legendäre Handelsroute zwischen China und Europa darstellte. Die Altstadt, ganz aus Lehmziegeln errichtet, ist von einer über zwei Kilometer langen, zehn Meter hohen und acht Meter mächtigen Stadtmauer einschließlich einer Zitadelle umgeben, die heute der Stadt ein ganz besonderes Flair verleihen.',
	},

	{ id: '147', 
	imageName: 'book/101b- Usbekistan, Chiwa - Mai 2011.jpg',
	title: '101b - Usbekistan, Chiwa - Mai 2011',
	subtitle: '',
	},

	{ id: '148', 
	imageName: 'book/102 Usbekistan, Samarkand, Registan Platz - Mai 2011.jpg',
	title: '102 - Usbekistan, Samarkand, Registan Platz - Mai 2011',
	subtitle: 'Obwohl Registan übersetzt eigentlich nur Sandplatz heißt, ist hier kein Sand zu sehen. Der Boden ist mit herrlichen Ornamenten aus Sandstein, Kalkstein und Kacheln bedeckt. Der Platz wird auf drei Seiten von alten islamischen Hochschulen, den sogenannten Medresen, gesäumt. Es handelt sich dabei um besonders prächtige und repräsentative Bauwerke aus dem 15. bis 17. Jahrhundert, die in ihrer Gesamtheit perfekt miteinander korrespondieren. Steht man in der Mitte des Platzes weiß man gar nicht, wohin man seinen Blick zuerst wenden soll. Wunderbare Mosaike zieren die Fronten aller Gebäude. Einige bezeichnen diesen Ort als den schönsten Platz der Welt. Sie mögen recht haben.',
	},

];
